/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

.custom-container {
    width: auto !important;
    margin-left: 2.75em !important;
    padding-left: 0px !important;
}
.margin-left-30 {
    margin-left: 15%;
}
.margin-left-40 {
    margin-left: 30%;
}
.font-family-proxima {
    font-family: proxima-nova, Helvetica, Arial, sans-serif !important;
}